<template>
  <!-- Modal -->
  <b-modal id="timezoneModalProfile" hide-footer button-size="lg">
    <template #modal-title> Change Profile Time Zone </template>
    <b-form>
      <div class="modal-body">
        <p
          style="
            margin-left: 3%;
            font-size: 15px;
            color: #808080;
            font-weight: bold;
          "
        >
          Timezone
        </p>
        <b-form-group
          label=""
          label-for="ratio"
          label-cols-md="auto"
          class="mb-3"
        >
          <b-form-select
            id="ratio"
            v-model="ShowTimezone"
            style="font-size: 15px; font-weight: bold; color: #01151c"
            :options="timeZoneoptions"
          ></b-form-select>
        </b-form-group>
      </div>
      <div class="modal-footer">
        <b-button
          type="button"
          id="cancelBtn"
          variant="outline-primary"
          @click="cancelFunction"
          >Cancel</b-button
        >
        <b-button type="button" @click="UpdateTimeZone" variant="success"
          >Update</b-button
        >
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      timezoneInfo: null,
      timeZoneoptions: [],
      ShowTimezone: null,
      updateTimezone: null
    };
  },
  methods: {
    ...mapActions(["updatePartner"]),
    cancelFunction() {
      this.$bvModal.hide("timezoneModalProfile");
    },
    UpdateTimeZone() {
      this.updateTimezone = this.timeZoneoptions.find(
        item => item.value == this.ShowTimezone
      );
       var payLoad = {
        timeZone: this.updateTimezone.text
      }
      axios
        .put(
          "/portal/api/TimeZone/" + JSON.parse(localStorage.getItem('organizationId')),
           payLoad
        )
        .then((response) => {
          this.$emit("update-location", true);
          this.$bvModal.hide("timezoneModalProfile");
        });
    },
    getTimezoneValue(timezone_key) {
      return this.timeZoneoptions.find(item => item.text == timezone_key);
    },

    getUnits: function() {
      axios.get("/portal/api/Customers/GetTimeZone").then(response => {
        this.timezoneInfo = response.data;
        if (response.statusText == "No Content") {
          // setting the timezone to some default value if api returns null- (GMT-4:00) Eastern Time (US and Canada)
          this.ShowTimezone = "America/New_York";
        } else {
          this.ShowTimezone = this.getTimezoneValue(this.timezoneInfo).value;
        }
      });
    },

    initializeTimezones: function() {
      var timezonesStr = {
        "Pacific/Midway": "(GMT-11:00) Midway Island, Samoa",
        "Pacific/Pago_Pago": "(GMT-11:00) Pago Pago",
        "Pacific/Honolulu": "(GMT-10:00) Hawaii",
        "America/Anchorage": "(GMT-8:00) Alaska",
        "America/Juneau": "(GMT-8:00) Juneau",
        "America/Vancouver": "(GMT-7:00) Vancouver",
        "America/Los_Angeles": "(GMT-7:00) Pacific Time (US and Canada)",
        "America/Tijuana": "(GMT-7:00) Tijuana",
        "America/Phoenix": "(GMT-7:00) Arizona",
        "America/Edmonton": "(GMT-6:00) Edmonton",
        "America/Denver": "(GMT-6:00) Mountain Time (US and Canada)",
        "America/Mazatlan": "(GMT-6:00) Mazatlan",
        "America/Regina": "(GMT-6:00) Saskatchewan",
        "America/Guatemala": "(GMT-6:00) Guatemala",
        "America/El_Salvador": "(GMT-6:00) El Salvador",
        "America/Managua": "(GMT-6:00) Managua",
        "America/Costa_Rica": "(GMT-6:00) Costa Rica",
        "America/Tegucigalpa": "(GMT-6:00) Tegucigalpa",
        "America/Chihuahua": "(GMT-6:00) Chihuahua",
        "America/Winnipeg": "(GMT-5:00) Winnipeg",
        "America/Chicago": "(GMT-5:00) Central Time (US and Canada)",
        "America/Mexico_City": "(GMT-5:00) Mexico City",
        "America/Panama": "(GMT-5:00) Panama",
        "America/Bogota": "(GMT-5:00) Bogota",
        "America/Lima": "(GMT-5:00) Lima",
        "America/Monterrey": "(GMT-5:00) Monterrey",
        "America/Montreal": "(GMT-4:00) Montreal",
        "America/New_York": "(GMT-4:00) Eastern Time (US and Canada)",
        "America/Indianapolis": "(GMT-4:00) Indiana (East)",
        "America/Puerto_Rico": "(GMT-4:00) Puerto Rico",
        "America/Caracas": "(GMT-4:00) Caracas",
        "America/Santiago": "(GMT-4:00) Santiago",
        "America/La_Paz": "(GMT-4:00) La Paz",
        "America/Guyana": "(GMT-4:00) Guyana",
        "America/Halifax": "(GMT-3:00) Halifax",
        "America/Montevideo": "(GMT-3:00) Montevideo",
        "America/Araguaina": "(GMT-3:00) Recife",
        "America/Argentina/Buenos_Aires": "(GMT-3:00) Buenos Aires, Georgetown",
        "America/Sao_Paulo": "(GMT-3:00) Sao Paulo",
        "Canada/Atlantic": "(GMT-3:00) Atlantic Time (Canada)",
        "America/St_Johns": "(GMT-2:30) Newfoundland and Labrador",
        "America/Godthab": "(GMT-2:00) Greenland",
        "Atlantic/Cape_Verde": "(GMT-1:00) Cape Verde Islands",
        "Atlantic/Azores": "(GMT+0:00) Azores",
        UTC: "(GMT+0:00) Universal Time UTC",
        "Etc/Greenwich": "(GMT+0:00) Greenwich Mean Time",
        "Atlantic/Reykjavik": "(GMT+0:00) Reykjavik",
        "Africa/Nouakchott": "(GMT+0:00) Nouakchott",
        "Europe/Dublin": "(GMT+1:00) Dublin",
        "Europe/London": "(GMT+1:00) London",
        "Europe/Lisbon": "(GMT+1:00) Lisbon",
        "Africa/Casablanca": "(GMT+1:00) Casablanca",
        "Africa/Bangui": "(GMT+1:00) West Central Africa",
        "Africa/Algiers": "(GMT+1:00) Algiers",
        "Africa/Tunis": "(GMT+1:00) Tunis",
        "Europe/Belgrade": "(GMT+2:00) Belgrade, Bratislava, Ljubljana",
        CET: "(GMT+2:00) Sarajevo, Skopje, Zagreb",
        "Europe/Oslo": "(GMT+2:00) Oslo",
        "Europe/Copenhagen": "(GMT+2:00) Copenhagen",
        "Europe/Brussels": "(GMT+2:00) Brussels",
        "Europe/Berlin":
          "(GMT+2:00) Amsterdam, Berlin, Rome, Stockholm, Vienna",
        "Europe/Amsterdam": "(GMT+2:00) Amsterdam",
        "Europe/Rome": "(GMT+2:00) Rome",
        "Europe/Stockholm": "(GMT+2:00) Stockholm",
        "Europe/Vienna": "(GMT+2:00) Vienna",
        "Europe/Luxembourg": "(GMT+2:00) Luxembourg",
        "Europe/Paris": "(GMT+2:00) Paris",
        "Europe/Zurich": "(GMT+2:00) Zurich",
        "Europe/Madrid": "(GMT+2:00) Madrid",
        "Africa/Harare": "(GMT+2:00) Harare, Pretoria",
        "Europe/Warsaw": "(GMT+2:00) Warsaw",
        "Europe/Prague": "(GMT+2:00) Prague Bratislava",
        "Europe/Budapest": "(GMT+2:00) Budapest",
        "Africa/Tripoli": "(GMT+2:00) Tripoli",
        "Africa/Cairo": "(GMT+2:00) Cairo",
        "Africa/Johannesburg": "(GMT+2:00) Johannesburg",
        "Africa/Khartoum": "(GMT+2:00) Khartoum",
        "Europe/Helsinki": "(GMT+3:00) Helsinki",
        "Africa/Nairobi": "(GMT+3:00) Nairobi",
        "Europe/Sofia": "(GMT+3:00) Sofia",
        "Europe/Istanbul": "(GMT+3:00) Istanbul",
        "Europe/Athens": "(GMT+3:00) Athens",
        "Europe/Bucharest": "(GMT+3:00) Bucharest",
        "Asia/Nicosia": "(GMT+3:00) Nicosia",
        "Asia/Beirut": "(GMT+3:00) Beirut",
        "Asia/Damascus": "(GMT+3:00) Damascus",
        "Asia/Jerusalem": "(GMT+3:00) Jerusalem",
        "Asia/Amman": "(GMT+3:00) Amman",
        "Europe/Moscow": "(GMT+3:00) Moscow",
        "Asia/Baghdad": "(GMT+3:00) Baghdad",
        "Asia/Kuwait": "(GMT+3:00) Kuwait",
        "Asia/Riyadh": "(GMT+3:00) Riyadh",
        "Asia/Bahrain": "(GMT+3:00) Bahrain",
        "Asia/Qatar": "(GMT+3:00) Qatar",
        "Asia/Aden": "(GMT+3:00) Aden",
        "Africa/Djibouti": "(GMT+3:00) Djibouti",
        "Africa/Mogadishu": "(GMT+3:00) Mogadishu",
        "Europe/Kiev": "(GMT+3:00) Kiev",
        "Europe/Minsk": "(GMT+3:00) Minsk",
        "Asia/Dubai": "(GMT+4:00) Dubai",
        "Asia/Muscat": "(GMT+4:00) Muscat",
        "Asia/Baku": "(GMT+4:00) Baku, Tbilisi, Yerevan",
        "Asia/Tehran": "(GMT+4:30) Tehran",
        "Asia/Kabul": "(GMT+4:30) Kabul",
        "Asia/Yekaterinburg": "(GMT+5:00) Yekaterinburg",
        "Asia/Tashkent": "(GMT+5:00) Islamabad, Karachi, Tashkent",
        "Asia/Calcutta": "(GMT+5:30) India",
        "Asia/Kolkata": "(GMT+5:30) Mumbai, Kolkata, New Delhi",
        "Asia/Kathmandu": "(GMT+5:45) Kathmandu",
        "Asia/Almaty": "(GMT+6:00) Almaty",
        "Asia/Dacca": "(GMT+6:00) Dacca",
        "Asia/Dhaka": "(GMT+6:00) Astana, Dhaka",
        "Asia/Rangoon": "(GMT+6:30) Rangoon",
        "Asia/Novosibirsk": "(GMT+7:00) Novosibirsk",
        "Asia/Krasnoyarsk": "(GMT+7:00) Krasnoyarsk",
        "Asia/Bangkok": "(GMT+7:00) Bangkok",
        "Asia/Saigon": "(GMT+7:00) Vietnam",
        "Asia/Jakarta": "(GMT+7:00) Jakarta",
        "Asia/Irkutsk": "(GMT+8:00) Irkutsk, Ulaanbaatar",
        "Asia/Shanghai": "(GMT+8:00) Beijing, Shanghai",
        "Asia/Hong_Kong": "(GMT+8:00) Hong Kong SAR",
        "Asia/Taipei": "(GMT+8:00) Taipei",
        "Asia/Kuala_Lumpur": "(GMT+8:00) Kuala Lumpur",
        "Asia/Singapore": "(GMT+8:00) Singapore",
        "Australia/Perth": "(GMT+8:00) Perth",
        "Asia/Yakutsk": "(GMT+9:00) Yakutsk",
        "Asia/Seoul": "(GMT+9:00) Seoul",
        "Asia/Tokyo": "(GMT+9:00) Osaka, Sapporo, Tokyo",
        "Australia/Darwin": "(GMT+9:30) Darwin",
        "Australia/Adelaide": "(GMT+9:30) Adelaide",
        "Asia/Vladivostok": "(GMT+10:00) Vladivostok",
        "Pacific/Port_Moresby": "(GMT+10:00) Guam, Port Moresby",
        "Australia/Brisbane": "(GMT+10:00) Brisbane",
        "Australia/Sydney": "(GMT+10:00) Canberra, Melbourne, Sydney",
        "Australia/Hobart": "(GMT+10:00) Hobart",
        "Asia/Magadan": "(GMT+11:00) Magadan",
        SST: "(GMT+11:00) Solomon Islands",
        "Pacific/Noumea": "(GMT+11:00) New Caledonia",
        "Asia/Kamchatka": "(GMT+12:00) Kamchatka",
        "Pacific/Fiji": "(GMT+12:00) Fiji Islands, Marshall Islands",
        "Pacific/Auckland": "(GMT+12:00) Auckland, Wellington",
        "Pacific/Apia": "(GMT+13:00)Independent State of Samoa"
      };

      // load time zones
      for (var key in timezonesStr) {
        if (timezonesStr.hasOwnProperty(key)) {
          var element = {};
          element.value = key;
          element.text = timezonesStr[key];
          this.timeZoneoptions.push(element);
        }
      }
    }
  },

  computed: {
    ...mapState({
      store: state => state.company
    })
  },

  mounted: function() {
    this.initializeTimezones();
    this.getUnits();
  }
};
</script>
